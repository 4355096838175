import { EmptyPoolStorageGenRepoImpl } from "domain/repository/EmptyPoolStorageGeneration/EmptyPoolStorageGenRepoImpl";
import { CompanyRepoImpl } from "domain/repository/Company/CompanyRepoImpl";
import { useEmptyPoolStorageGenTracked } from "presentation/store/EmptyPoolStorageGeneration/EmptyPoolStorageGenProvider";
import { EmptyPoolStorageGenVM } from "presentation/viewModel/EmptyPoolStorageGeneration/EmptyPoolStorageGenVM";
import { useMemo } from "react";

export const useEmptyPoolStorageGenVM = () => {
    const [, setEmptyPoolStorageGenState] = useEmptyPoolStorageGenTracked();
    const emptyPoolStorageGenVM = useMemo(() =>
        EmptyPoolStorageGenVM({
            dispatch: [setEmptyPoolStorageGenState],
            emptyPoolStorageGenRepo: EmptyPoolStorageGenRepoImpl(),
            companyRepo: CompanyRepoImpl(),
        }), [setEmptyPoolStorageGenState])

    return emptyPoolStorageGenVM
}

