import { EmptyPoolStorageEntity } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageEntity";
import { EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA, EmptyPoolStorageGenSearchCriteria } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageGenSearchCriteria";
import { CompanyRepository } from "domain/repository/Company/CompanyRepo";
import { EmptyPoolStorageGenRepository } from "domain/repository/EmptyPoolStorageGeneration/EmptyPoolStorageGenRepo";
import _ from "lodash";
import { DropdownProps } from "presentation/model/DropdownProps";
import { EmptyPoolStorageGenModel } from "presentation/model/EmptyPoolStorageGeneration/EmptyPoolStorageGenModel";
import { Dispatch, SetStateAction } from "react";
import BaseViewModel from "../BaseViewModel";


interface EmptyPoolStorageGenVMProps extends BaseViewModel {
    dispatch: [
        Dispatch<SetStateAction<EmptyPoolStorageGenModel>> | ((value: SetStateAction<EmptyPoolStorageGenModel>) => void),
    ],
    emptyPoolStorageGenRepo: EmptyPoolStorageGenRepository,
    companyRepo: CompanyRepository,
    
}

export const EmptyPoolStorageGenVM = ({dispatch,emptyPoolStorageGenRepo,companyRepo}:EmptyPoolStorageGenVMProps) => {
    const [emptyPoolStorageGenDispatch] = dispatch;

    const loadDropdownOption = async() => {

        await companyRepo.getAllCompanyForCombobox().then(
            companies => {
                let companyCodeDropdownOptions = companies?.map((company) => ({
                    dropdownLabel: company.companyCode,
                    tagLabel: company.companyCode,
                    value: company.companyCode,
                })) ?? []
                companyCodeDropdownOptions = _.orderBy(companyCodeDropdownOptions, "dropdownLabel");

                emptyPoolStorageGenDispatch(prevState => ({
                    ...prevState,
                    dynamicOptions: {
                        ...prevState.dynamicOptions,
                        chargeOnCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                        billToCompanyDropdownOptions: [
                            ...companyCodeDropdownOptions],
                    }
                }))
            }
        )

    }

    const searchEmptyPoolStorageDatas = async(searchCriteria: EmptyPoolStorageGenSearchCriteria) => {
        emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                emptyPoolDatas:[],
                selectedEmptyPoolDatas: []
            }
        })
        await emptyPoolStorageGenRepo.searchEmptyPoolStorageDatas({
            empPoolStorageSrchCriteria: searchCriteria,
            empPoolStorageDataList:[]            
        }).then((data) => {
            emptyPoolStorageGenDispatch(prevState => {
                return {
                    ...prevState,
                    emptyPoolDatas: data,
                    selectedEmptyPoolDatas: [],
                    searchCriteria: {...searchCriteria}
                }
            })
        }).catch((error) => {
            return [];
        })
    }

    const onRemoveSearchCriteria = (fieldName: string, isSearch: boolean = true) => {
        let resetSearchCriteriaPerField = { [fieldName]: EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA[fieldName] }
        

            emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                searchCounter: isSearch ? prevState.searchCounter + 1 : prevState.searchCounter,
                searchCriteria: { ...prevState.searchCriteria, ...resetSearchCriteriaPerField }
            }
        })
    }

    const onRemoveAllSearchCriteria = () => {
        emptyPoolStorageGenDispatch(prevState => {
            prevState.currentTableRef?.current?.api.setServerSideDatasource({
                getRows: (params: any) => {
                    params.success({
                        rowData: [],
                        rowCount: 0
                    })
                },
            })
            
            return {
                ...prevState, searchCounter: 0, searchCriteria: { ...EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA }
            }
        })
    }

    const onMultipleDropdownChange = (e: any, fieldName: string) => {
        emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                searchCriteria: {
                    ...prevState.searchCriteria,
                    [fieldName]: _.uniq(e?.map((item: DropdownProps) => item.value)),
                }
            }
        });
    };

    // const onSearchInputTextChange = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    //     emptyPoolStorageGenDispatch(prevState => ({
    //         ...prevState,
    //         searchCriteria: {
    //             ...prevState.searchCriteria,
    //             [fieldName]: e.target.value,
    //         }
    //     }))
    // };
  
    const onDateRangeChange = (startDate: any, endDate: any,  dateFields:{startField:string, endField: string}) => {
        emptyPoolStorageGenDispatch(prevState => ({
            ...prevState,
            searchCriteria: {
                ...prevState.searchCriteria,
                [dateFields.startField]: startDate,
                [dateFields.endField]: endDate,
            }
        }))
    }

    const onCloseScreen = () => {
        document.dispatchEvent(new CustomEvent('closeEmptyPoolStorageGeneration'))
    }

    const onShowLoading = () => {
        emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                isLoading: true,
            }
        })
    }

    const onHideLoading = () => {
        emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                isLoading: false,
            }
        })
    }
  
    const onSearchClick = async() => {
        emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                isShowCriteriaPanel: !prevState.isShowCriteriaPanel
            }
        });       
    }

    const onGenerate = async(selectEmptyPoolStorageDatas: EmptyPoolStorageEntity[]) => {
        await emptyPoolStorageGenRepo.generateEmptyPoolStorageDatas({
            empPoolStorageSrchCriteria: {...EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA},
            empPoolStorageDataList: selectEmptyPoolStorageDatas}).then((data) => {
                if(data && data === "success"){
                    emptyPoolStorageGenDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"genEmptyPoolSuccess":""}    
                        }
                    })
                }else{
                    emptyPoolStorageGenDispatch(prevState => {
                        return {
                            ...prevState,
                            allFormState: {"genEmptyPoolFail":data}    
                        }
                    })
                }
            }).catch((error) => {
                emptyPoolStorageGenDispatch(prevState => {
                    return {
                        ...prevState,
                        allFormState: {"genEmptyPoolFail":"Generation error."}    
                    }
                })
        })     
    }

    const onDeactivate = async(selectEmptyPoolStorageDatas: EmptyPoolStorageEntity[]) => {
        await emptyPoolStorageGenRepo.deactivateEmptyPoolStorageDatas({
            empPoolStorageSrchCriteria: {...EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA},
            empPoolStorageDataList: selectEmptyPoolStorageDatas})
    }

    const updateSelectedEmpPoolDatas = (rows:any[]) => {
        emptyPoolStorageGenDispatch(prevState => {
            return {
                ...prevState,
                selectedEmptyPoolDatas: rows                       
            }
        })
    }
   
    return {
        searchEmptyPoolStorageDatas: searchEmptyPoolStorageDatas,
        onGenerate: onGenerate,
        loadDropdownOption: loadDropdownOption,
        onRemoveSearchCriteria: onRemoveSearchCriteria,
        onRemoveAllSearchCriteria: onRemoveAllSearchCriteria,        
        onMultipleDropdownChange: onMultipleDropdownChange,
        onDateRangeChange: onDateRangeChange,
        onSearchClick: onSearchClick,
        onCloseScreen: onCloseScreen,        
        onShowLoading: onShowLoading,
        onHideLoading: onHideLoading,
        onDeactivate: onDeactivate,
        updateSelectedEmpPoolDatas: updateSelectedEmpPoolDatas
    }
}