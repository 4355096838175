import { EmptyPoolStorageGenSearchCriteria } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageGenSearchCriteria";
import { EmptyPoolStorageGenConstant } from "presentation/constant/EmptyPoolStorageGeneration/EmptyPoolStorageGenConstant";
import { useEmptyPoolStorageGenVM } from "presentation/hook/EmptyPoolStorageGeneration/useEmptyPoolStorageGenVM";
import { useEmptyPoolStorageGenTracked } from "presentation/store/EmptyPoolStorageGeneration/EmptyPoolStorageGenProvider";
import { useCallback, useState } from "react";
import { HPHButton, IconButton, Loader } from "veronica-ui-component/dist/component/core";
import { SidebarActionBar, SidebarActionCross, SidebarTitle, Sidebarheader } from "veronica-ui-component/dist/component/core/styled/uiFramework.styled";
import EmptyPoolStorageGenSearchForm from "./EmptyPoolStorageGenSearchForm";

const EmptyPoolStorageGenSearchPanel = () => {
    const EMP_POOL_STORAGE_GEN_CONSTANT= EmptyPoolStorageGenConstant.Charge;
    const emptyPoolStorageGenVM = useEmptyPoolStorageGenVM();
    const [emptyPoolStorageGenState] = useEmptyPoolStorageGenTracked();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const resetButtonClicked = useCallback(() => {
        emptyPoolStorageGenVM.onRemoveAllSearchCriteria();
    }, [emptyPoolStorageGenVM]);
    
    const searchButtonClicked = useCallback((searchCriteria:EmptyPoolStorageGenSearchCriteria) => {
        setIsLoading(true);
        emptyPoolStorageGenVM.searchEmptyPoolStorageDatas(searchCriteria).then((data) => {
            setIsLoading(false);
        }).catch(error => {
            setIsLoading(false);
        })
        
    }, [emptyPoolStorageGenVM])

    return (
        <div className='side-form-content-left-wrapper'>
            <div className={'flex-row'}>
                <div className={'flex-row-item flex-row-item-full-width'}>
                    <Sidebarheader>
                        <SidebarTitle>{EMP_POOL_STORAGE_GEN_CONSTANT.SEARCH_CRITERIA}</SidebarTitle>
                        <SidebarActionCross>
                            <IconButton
                                fileName="Icon-cross" size="medium" toolTipText='Close' toolTipArrow={false} onClick={emptyPoolStorageGenVM.onSearchClick} />
                        </SidebarActionCross>
                    </Sidebarheader>                
                </div>
            </div>

            {isLoading && <Loader Indicator="Spinner" size="Medium" /> }

            <div className={'add-edit-form'} style={{ maxHeight:'75vh' , height:'75vh', overflow: 'auto'}}>
                <div className="flex-row-auto">
                    <div className={'flex-row-item'}>
                        
                        {/* Stack Form Start */}
                        <EmptyPoolStorageGenSearchForm/>
                    </div>                  
                </div>
            </div>
            <SidebarActionBar>
                <HPHButton label={'Reset'} size={'Small'} theme={'Secondary'} onClick={resetButtonClicked} />
                <HPHButton label={'Search'} size={'Small'} theme={'Primary'} onClick={() => searchButtonClicked(emptyPoolStorageGenState.searchCriteria)} />
            </SidebarActionBar>
        </div>
    );
}

export default EmptyPoolStorageGenSearchPanel;
