import { EmptyPoolStorageEntity } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageEntity";
import { EmptyPoolStorageGenRequestEntity } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageGenRequestEntity";
import { axiosPostData } from "../axios/AxiosBasicImpl";
import chargeAxiosInstance from "../axios/chargeAxiosInstance";
import { EmptyPoolStorageGenRepository } from "./EmptyPoolStorageGenRepo";

export const EmptyPoolStorageGenRepoImpl = () : EmptyPoolStorageGenRepository => {

    const url = "/v1/emptyPoolStorageGen/search"

    const searchEmptyPoolStorageDatas = async(requestEntity: EmptyPoolStorageGenRequestEntity) : Promise<EmptyPoolStorageEntity[]> => {
        return axiosPostData(chargeAxiosInstance, `${url}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }


    const deactivateEmptyPoolStorageDatas = async(requestEntity: EmptyPoolStorageGenRequestEntity) : Promise<string> => {
        const newUrl = "/v1/emptyPoolStorageGen/deactivate";
        
        return axiosPostData(chargeAxiosInstance,`${newUrl}`, requestEntity).then(res => {
            const data = res.data;
            return data;
        }).catch(err => {
            return [];
        });
    }
    const generateEmptyPoolStorageDatas = async(requestEntity: EmptyPoolStorageGenRequestEntity) : Promise<string> => {
        const newUrl = "/v1/emptyPoolStorageGen/generate";
        
        return axiosPostData(chargeAxiosInstance,`${newUrl}`, requestEntity).then(res => {
            let data = res.data;
            if(res.code === "500"){
                return res.msg;
            }
            return data;
        }).catch(err => {
            return [];
        });
    }

    return {
        searchEmptyPoolStorageDatas: searchEmptyPoolStorageDatas,
        generateEmptyPoolStorageDatas: generateEmptyPoolStorageDatas,
        deactivateEmptyPoolStorageDatas: deactivateEmptyPoolStorageDatas       
    }
}