import { ChargeDetailConstant } from "../ChargeDetailMaintenance/ChargeDetailConstant";

export const EmptyPoolStorageGenConstant = {
    title : 'Empty Pool Storage Generation',
    Charge: {
        SEARCH_CRITERIA: ChargeDetailConstant.Charge.SEARCH_CRITERIA,
        DATA_LIST: 'Data List',
        CHARGE_ON_COMPANY: 'Charge On Company',
        BILL_TO_COMPANY: 'Bill To Company',
        OPS_DATE: ChargeDetailConstant.Charge.OPS_DATE,
        OPS_DATE_RANGE: 'Ops Date Range',
        OPS_LINE: 'Ops Line',

        GENERATE: 'Generate',
        DEACTIVATE: 'Deactivate',
    }
}