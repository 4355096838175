import { EmptyPoolStorageGenConstant } from "presentation/constant/EmptyPoolStorageGeneration/EmptyPoolStorageGenConstant";
import { useEmptyPoolStorageGenVM } from "presentation/hook/EmptyPoolStorageGeneration/useEmptyPoolStorageGenVM";
import { useEmptyPoolStorageGenTracked } from "presentation/store/EmptyPoolStorageGeneration/EmptyPoolStorageGenProvider";
import { CriteriaItemContainer } from "presentation/view/components/CriteriaItemContainer";
import DateRangePickerComponent from "presentation/view/components/DateRangePickerComponent";
import { memo, useMemo } from "react";
import { InputDropdown } from "veronica-ui-component/dist/component/core";

const EmptyPoolStorageGenSearchForm = () => {    
    const EMP_POOL_STORAGE_GEN_CONSTANT = EmptyPoolStorageGenConstant.Charge;
    const [emptyPoolStorageGenerationState] = useEmptyPoolStorageGenTracked();
    const emptyPoolStorageGenSearchCriteria = emptyPoolStorageGenerationState.searchCriteria;
    const emptyPoolStorageGenVM = useEmptyPoolStorageGenVM();
    
    const memoChargeOnCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EMP_POOL_STORAGE_GEN_CONSTANT.CHARGE_ON_COMPANY}
                inputType="freeText"
                width="330px"
                mode={'multiple'}
                sort={false}
                value={emptyPoolStorageGenSearchCriteria.chargeOnCompanies?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => emptyPoolStorageGenVM.onMultipleDropdownChange(e, 'chargeOnCompanies')}
                options={emptyPoolStorageGenerationState.dynamicOptions.chargeOnCompanyDropdownOptions}/>
        </div>
    , [EMP_POOL_STORAGE_GEN_CONSTANT.CHARGE_ON_COMPANY, emptyPoolStorageGenSearchCriteria.chargeOnCompanies, emptyPoolStorageGenerationState.dynamicOptions.chargeOnCompanyDropdownOptions, emptyPoolStorageGenVM])
    
    const memoBillToCompany = useMemo(() =>
        <div className='im-flex-row-item'>
            <InputDropdown
                label={EMP_POOL_STORAGE_GEN_CONSTANT.BILL_TO_COMPANY}
                inputType="freeText"
                width="330px"
                mode={'multiple'}
                sort={false}
                value={emptyPoolStorageGenSearchCriteria.billToCompanies?.map((item) => ({
                    value: item
                }))}
                onChange={(e) => emptyPoolStorageGenVM.onMultipleDropdownChange(e, 'billToCompanies')}
                options={emptyPoolStorageGenerationState.dynamicOptions.billToCompanyDropdownOptions}/>
        </div>
    , [EMP_POOL_STORAGE_GEN_CONSTANT.BILL_TO_COMPANY, emptyPoolStorageGenSearchCriteria.billToCompanies, emptyPoolStorageGenerationState.dynamicOptions.billToCompanyDropdownOptions, emptyPoolStorageGenVM])
    
    const memoOpsDateFromTo = useMemo(() =>
        <div className='im-flex-row-item'>
            <DateRangePickerComponent
                label={EMP_POOL_STORAGE_GEN_CONSTANT.OPS_DATE_RANGE}
                width='330px'
                fieldNames={{startField:"opsDateFrom", endField:"opsDateTo"}}
                dateRange={{startDate:emptyPoolStorageGenSearchCriteria.opsDateFrom, endDate:emptyPoolStorageGenSearchCriteria.opsDateTo}}
                onDatesChange={emptyPoolStorageGenVM.onDateRangeChange}
            />                
        </div>
    , [EMP_POOL_STORAGE_GEN_CONSTANT.OPS_DATE_RANGE, emptyPoolStorageGenSearchCriteria.opsDateFrom, emptyPoolStorageGenSearchCriteria.opsDateTo, emptyPoolStorageGenVM.onDateRangeChange])
 
    return <>        
        
        <CriteriaItemContainer>
            {emptyPoolStorageGenerationState.enabledSearchCriteria.chargeOnCompanies ? memoChargeOnCompany : null}
        </CriteriaItemContainer> 
        <CriteriaItemContainer>
            {emptyPoolStorageGenerationState.enabledSearchCriteria.billToCompanies ? memoBillToCompany : null}
        </CriteriaItemContainer> 
        <CriteriaItemContainer>
            {emptyPoolStorageGenerationState.enabledSearchCriteria.opsDate ? memoOpsDateFromTo : null}
        </CriteriaItemContainer>       

    </>;
}
export default memo(EmptyPoolStorageGenSearchForm);