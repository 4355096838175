import { EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA } from "domain/entity/EmptyPoolStorageGeneration/EmptyPoolStorageGenSearchCriteria";
import { useEmptyPoolStorageGenVM } from "presentation/hook/EmptyPoolStorageGeneration/useEmptyPoolStorageGenVM";
import { useEmptyPoolStorageGenTracked } from "presentation/store/EmptyPoolStorageGeneration/EmptyPoolStorageGenProvider";
import { memo, useEffect, useState } from "react";
import { Loader, SliderPanel } from "veronica-ui-component/dist/component/core";
import EmptyPoolStorageGenTitleBar from "./EmptyPoolStorageGenTitleBar";
import EmptyPoolStorageGenSearchPanel from "./LeftPanel/EmptyPoolStorageGenSearchPanel";
import EmptyPoolStorageGenTablePanel from "./Table/EmptyPoolStorageGenTablePanel";


const EmptyPoolStorageGeneration:React.FC = () => {
    const [ emptyPoolStorageGenState ] = useEmptyPoolStorageGenTracked();
    const { isShowCriteriaPanel } = emptyPoolStorageGenState;
    const emptyPoolStorageGenVM = useEmptyPoolStorageGenVM();
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const initialScreen = async() => {   
            try {        
                const fromDate = new Date();
                fromDate.setDate(fromDate.getDate() - 9);
                fromDate.setHours(0, 0, 0, 0);
                const toDate = new Date();
                toDate.setHours(0, 0, 0, 0);
                toDate.setDate(toDate.getDate() + 1);

                let newSearchCriteria = {...EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA} 
                newSearchCriteria = { ...newSearchCriteria, 
                    opsDateFrom: fromDate, 
                    opsDateTo: toDate
                };
                
                await Promise.allSettled([
                    emptyPoolStorageGenVM.loadDropdownOption(),      
                    emptyPoolStorageGenVM.searchEmptyPoolStorageDatas(newSearchCriteria)              
                ]);
                
            } catch(error) {
            }
        }
        initialScreen().then((data) => {            
            setIsLoading(false)
        }).catch(error => {            
            setIsLoading(false)
        });
    }, [emptyPoolStorageGenVM])


    
    if (isLoading) return <Loader Indicator="Stripe" size="Large" />;
    
    return <>
        <div className={`main-comp-wrapper${isShowCriteriaPanel ? '' : ' im-hide-side-form-draggable'}`}>
           <EmptyPoolStorageGenTitleBar/>            

            <SliderPanel
                isOpen={true}
                draggable={false}
                leftSectionWidth={isShowCriteriaPanel?"25%":"0%"}
                rightSectionWidth={isShowCriteriaPanel?"75%":"100%"}
                leftChildren={<EmptyPoolStorageGenSearchPanel/>}    
                rightChildren={<EmptyPoolStorageGenTablePanel/>}            
                />
        </div>
    </>
}

export default memo(EmptyPoolStorageGeneration);