import { defineColumn, transferRowDataInternal } from "presentation/view/components/TableWrapper/BasicTableConstants";
import { EmptyPoolStorageGenConstant } from "./EmptyPoolStorageGenConstant";

const EMP_POOL_STORAGE_GEN_CONSTANT = EmptyPoolStorageGenConstant.Charge;
let dateFieldList:string[] = [];
let dateTimeFieldList:string[] = [];
export const INITIAL_EMP_POOL_STORAGE_GEN_COL_DEF: any[] = [
    {
        headerName: EMP_POOL_STORAGE_GEN_CONSTANT.CHARGE_ON_COMPANY,
        field: 'chargeOnCompany',
        enableRowGroup: false,
        enablePivot: true,
        enableValue: false,
        rowGroup: false,
        filter: false,
        width: 250,
        pinned: "left",
        checkboxSelection: true,
        headerCheckboxSelection: true,
    },
    {
        headerName: EMP_POOL_STORAGE_GEN_CONSTANT.BILL_TO_COMPANY,
        field: 'billToCompany',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 250,
    },
    {
        headerName: EMP_POOL_STORAGE_GEN_CONSTANT.OPS_DATE,
        field: 'opsDate',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
        dataType: "date"
    },
    {
        headerName: EMP_POOL_STORAGE_GEN_CONSTANT.OPS_LINE,
        field: 'billCode',
        enableRowGroup: true,
        enablePivot: true,
        enableValue: true,
        filter: false,
        width: 150,
    }

].map((col, index) => {
    const cellRenderers:{[key:string]:((params:{[key:string]:string}, fieldName:string) => {})} = {};
    return defineColumn(col, index, dateFieldList, dateTimeFieldList, [], cellRenderers);
    }
);

export const transferRowData = (data:any[]) => {
    const externalFnctions:{[key:string]:((fieldName:string, row:any) => {})} = {};
    return transferRowDataInternal(data, dateFieldList, dateTimeFieldList, [], externalFnctions);
}