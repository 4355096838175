export interface EmptyPoolStorageGenSearchCriteria{
    
    chargeOnCompanies?: string[] | null,
    billToCompanies?: string[] | null,
    opsDateFrom?: Date | null,
    opsDateTo?: Date | null    

    [key: string]: string[] | string | boolean | number | Date | null | undefined
}

export const EMPTY_EMP_POOL_STORAGE_GEN_SEARCH_CRITERIA : EmptyPoolStorageGenSearchCriteria = {

    chargeOnCompanies: [],
    billToCompanies: [],
    opsDateFrom: null,
    opsDateTo: null    
}