import { EMPTY_EMP_POOL_STORAGE_GEN_MODEL } from 'presentation/model/EmptyPoolStorageGeneration/EmptyPoolStorageGenModel';
import { useState } from 'react';
import { createContainer } from "react-tracked";

const {
    Provider: EmptyPoolStorageGenProvider,
    useTracked: useEmptyPoolStorageGenTracked
} = createContainer(() => useState(EMPTY_EMP_POOL_STORAGE_GEN_MODEL), {concurrentMode: true});
export { EmptyPoolStorageGenProvider, useEmptyPoolStorageGenTracked };

