export interface EmptyPoolStorageGenEnabledSearchCriteria {
    all: boolean,
    chargeOnCompanies:boolean,
    billToCompanies:boolean,
    opsDate: boolean

    [key: string]: boolean,
}

export const DEFAULT_ENABLED_EMPTY_POOL_STORAGE_GEN_SEARCH_CRITERIA: EmptyPoolStorageGenEnabledSearchCriteria = {
    all: true,
    chargeOnCompanies:true,
    billToCompanies:true,
    opsDate:true
}